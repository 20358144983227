<template>
  <div class="exhibitionManageBox">
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <el-form-item label="会议名称">
            <el-input v-model="state.queryForm.meetingName" class="mr-10" placeholder="请输入会议名称" clearable />
          </el-form-item>
          <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="6" v-for="(item, index) in state.list" :key="index">
        <div class="box-card">
          <div class="title">
            <div class="name">{{ item.meetingName }}</div>
            <el-icon class="editIcon" v-if="hasPermission(['exhibition:edit'])" @click="handleEdit(item)">
              <Edit />
            </el-icon>
          </div>
          <div class="form">
            <div class="form-item">
              <div class="label">展会状态</div>
              <div class="item">
                <div class="yishangjia iconbox" v-if="item.status == 'PUBLISHED'">
                  已发布
                </div>
                <div class="yixiajia iconbox" v-else>草稿</div>
              </div>
            </div>
            <div class="form-item">
              <div class="label">展会开始时间</div>
              <div class="item">
                {{ item.startTime ? filterTime(item.startTime) : '暂无' }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">展会结束时间</div>
              <div class="item">
                {{ item.endTime ? filterTime(item.endTime) : '暂无' }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">创建人姓名</div>
              <div class="item">
                {{ item.hostName ? item.hostName : '暂无' }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">创建人手机号</div>
              <div class="item">
                {{ item.hostPhone ? item.hostPhone : '暂无' }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">展会Code</div>
              <div class="item">
                {{ item.meetingCode }}
              </div>
            </div>
          </div>
          <div class="controllBox">
            <div class="btn" @click="handleShareChannel(item)" v-if="hasPermission(['user:register:channel:list'])">
              分享渠道
            </div>
            <div class="btn" @click="handlePreview(item)" v-if="hasPermission(['user:qrcode:getQrCodeUrls'])">
              预览
            </div>

            <!-- <div class="menuItem">
              <div class="btn" @click="handleChannel(item)" v-if="hasPermission(['user:register:channel:list'])">
                官方渠道
              </div>
            </div> -->

            <div class="menuItem">
              <div class="btn" @click="download(item)" v-loading.fullscreen.lock="fullscreenLoading">
                报名信息导出
              </div>
            </div>

            <div class="menuItem">
              <div class="btn" @click="handleshareCodeChannel(item)" v-if="hasPermission(['user:register:channel:list'])">
                邀请码渠道
              </div>
            </div>

            <div class="menuItem">
              <div class="btn" @click="goToAiSentence(item)">
                AI金句
              </div>
            </div>

            <div class="menuItem">
              <div class="btn" @click="handleRegister(item)" v-if="state.role">
                报名方式配置
              </div>
            </div>

          </div>

        </div>
      </el-col>
    </el-row>
    <el-pagination v-if="state.total > 9" background :current-page="state.queryForm.pageNum" :layout="state.layout" :page-size="state.queryForm.pageSize" :total="state.total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <!-- 编辑 -->
    <edit-com-meet ref="refExDialog" @noticeRefresh="getData" />
    <!-- <editComMeet ref="refExDialog" @noticeRefresh="getData" /> -->
    <!-- 预览 -->
    <preview-com ref="refExPreview" :url="state.qrcodeUrl" />
    <generateWxVue ref="generateWxRef"></generateWxVue>
    <export-register ref="exportRegisterRef"></export-register>

    <holdMeetRegister ref="holdMeetRegisterRef"></holdMeetRegister>
  </div>
</template>
<script>
import generateWxVue from '@/components/generateWx/generateWx.vue'
import exportRegister from './components/exportRegister.vue'
import holdMeetRegister from './components/holdMeetRegister.vue'
export default {
  name: 'ExhibitionManage',
  components: { generateWxVue, exportRegister, holdMeetRegister },
}
</script>
<script setup>
import { onActivated, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useComponent } from './components/index'
import { Edit } from '@element-plus/icons'
import {
  getAllHoldMeetingManageList,
  exportExOrderReport,
  exportMediaRegisterReport,
  exportExTicketReport,
  exportExSignUpReport,
  pushDataToGate,
  getQrcodeUrls,
  exportVisitorRegisterReport,
} from '@/api/exhibitionManage'
import { debounce } from '@/utils/debounce'
import { ElMessageBox, ElMessage } from 'element-plus'

import { parseTime } from '@/utils/index'
import { hasPermission } from '@/utils/permission'
// 引入组件
const { editComMeet, previewCom } = useComponent()
const filterExStatus = {
  true: '已上架',
  false: '已下架',
  null: '暂无',
}
const router = useRouter()
const refExDialog = ref(null) // 编辑弹框
const refExPreview = ref(null) // 预览弹框
const generateWxRef = ref(null) // 预览弹框
const loading = ref(false) // 列表动画加载
const associationRef = ref(null) // 列表动画加载
const exportRegisterRef = ref(null)
const holdMeetRegisterRef = ref(null)

const filterTime = (val) => {
  return val ? parseTime(val) : '暂无'
}

const state = reactive({
  queryForm: {
    meetingName: '',
    pageNum: 1,
    pageSize: 12,
    startTime: '',
    endTime: '',
  },
  role: false,
  envNode: '',
  qrCodeParams: {},
  permissionTarget: [''],
  size: 'large',
  collapseItem: 0,
  controllBtnWidth: '100%',
  total: 0,
  layout: 'prev, pager, next',
  list: [],
  qrcodeUrl: '', // 二维码
  typeOptions: [
    {
      label: '全部',
      value: '',
    },
    {
      label: '上架中',
      value: 'true',
    },
    {
      label: '已下架',
      value: 'false',
    },
  ],
})

const handleShareChannel = (row) => {
  if (row.meetingCode) {
    router.push({
      path: '/exhibitionManage/shareChannel',
      query: {
        meetingCode: row.meetingCode,
      },
    })
  }
}

const getData = async () => {
  loading.value = true
  const { data } = await getAllHoldMeetingManageList(state.queryForm)
  state.list = data.data
  state.total = data.total || 0
  try {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    state.role = userInfo.role.indexOf('EVENT_ADMIN') > -1
    state.envNode = process.env.NODE_ENV
  } catch (e) {
    state.role = false
  }
  setTimeout(() => {
    loading.value = false
  }, 500)
}

const handlePushGate = (row) => {
  ElMessageBox.confirm('是否确认推送数据至租达人闸机', '提示', {
    distinguishCancelAndClose: true,
    confirmButtonText: '确认',
    cancelButtonText: '取消',
  }).then(() => {
    pushDataToGate({ value: row.code })
      .then((res) => {
        ElMessage({
          message: '推送成功',
          type: 'success',
        })
      })
      .catch((err) => {
        ElMessage.error('推送失败')
      })
  })
}
// 下拉事件
const handleChangeSelect = (e) => {
  state.queryForm.isShelf = e
}
const handleCloseDialog = () => {
  refExDialog.value.handleClose()
}
// 查询
const handleQueryData = debounce(() => {
  state.queryForm.pageNum = 1
  state.collapseItem = 0
  getData()
})
// 预览
const handlePreview = (row) => {
  // getQrcodeUrls(row.code, 'HOLD_EXHIBITION').then((res) => {
  //   state.qrcodeUrl = res.data[0].qrcodeUrl
  //   refExPreview.value.handleOpen()
  // })
  console.log(row)
  const qrCodeParams = {
    autoColor: false,
    b: 0,
    env: '',
    g: 0,
    jumpPath: row.meetingCode,
    jumpType: '',
    logo: '',
    params: `{"meetingCode":"${row.meetingCode}"}`,
    path: 'pages/index/index',
    r: 0,
    scenesType: 'USER_HOLD_MEETING',
    width: 1280,
    miniPath: `subPackage/meet/holdMeet/detail?code=${row.meetingCode}`,
  }
  generateWxRef.value.handleOpen(row, qrCodeParams)
}

const handleRegister = (row) => {
  console.log(row)
  holdMeetRegisterRef.value.handleOpen(row)
}

// 编辑
const handleEdit = (row) => {
  refExDialog.value.handleOpen({ ...row })
}
// 条数
const handleSizeChange = debounce((pageSize) => {
  state.queryForm.pageSize = pageSize
  state.collapseItem = 0
  getData()
})
// 页码
const handleCurrentChange = debounce((pageNum) => {
  state.queryForm.pageNum = pageNum
  state.collapseItem = 0
  getData()
})
// 跳转门票配置
const handleJumpTicketsConfig = (row) => {
  router.push({
    path: '/exhibitionManage/ticketsConfig',
    query: { meetingCode: row.code },
  })
}

const handleIdentity = (row) => {
  router.push({
    path: '/exhibitionManage/identity',
    query: { meetingCode: row.code },
  })
}
// 跳转门票管理
const handleJumpTicketsManage = (row) => {
  router.push({
    path: '/exhibitionManage/ticketsManage',
    query: { meetingCode: row.code },
  })
}

// 跳转购票渠道
const handleChannelCode = (row) => {
  console.log(row)
  try {
    router.push({
      path: '/exhibitionManage/channelManage',
      query: { meetingCode: row.meetingCode },
    })
  } catch (e) {
    console.log(e)
  }
}

// 跳转邀请码渠道
const handleshareCodeChannel = (row) => {
  router.push({
    path: '/exhibitionManage/shareCodeChannel',
    query: { meetingCode: row.meetingCode, meetingType: 'HOLD_MEETING' },
  })
}

// 跳转论坛关联
const handleForume = (row) => {
  router.push({
    path: '/exhibitionManage/relatedForum',
    query: { meetingCode: row.code },
  })
}
// 活动关联
const handleActiveAssociation = (row) => {
  // state.ticketEditRef.showEdit(row)
  router.push({
    path: '/exhibitionManage/association',
    query: { meetingCode: row.code, meetingType: row.type },
  })
}
// 跳转报名渠道
const handleChannel = (row) => {
  if (row.meetingCode) {
    router.push({
      path: '/exhibitionManage/channelDefend',
      query: { meetingCode: row.meetingCode, type: 'HOLD_MEETING' },
    })
  }
}

// 跳转AI 金句
const goToAiSentence = (row) => {
  router.push({
    path: '/exhibitionManage/aiSentence',
    query: {
      meetingCode: row.meetingCode,
      type: 'HOLD_MEETING',
      isOpenAiAbstractSentence: row.isOpenAiAbstractSentence,
      meetingName: row.meetingName,
    },
  })
}

const fullscreenLoading = ref(false)
const downLoadReport = (row, type) => {
  console.log(row, type)
  fullscreenLoading.value = true
  if (type == 'order') {
    exportExOrderReport(row.code).then((res) => {
      fullscreenLoading.value = false
      if (res.data.success) {
        window.open(res.data.fileUrl)
      }
    })
  }

  if (type == 'media') {
    fullscreenLoading.value = true
    exportMediaRegisterReport(row.code).then((res) => {
      fullscreenLoading.value = false
      if (res.data.success) {
        window.open(res.data.fileUrl)
      }
    })
  }
  if (type == 'ticket') {
    fullscreenLoading.value = true
    exportExTicketReport(row.code).then((res) => {
      // 以excel文件为例
      fullscreenLoading.value = false
      if (res.data.success) {
        window.open(res.data.fileUrl)
      }
    })
  }
  if (type == 'signUp') {
    fullscreenLoading.value = true
    exportExSignUpReport(row.code).then((res) => {
      // 以excel文件为例
      fullscreenLoading.value = false
      if (res.data.success) {
        window.open(res.data.fileUrl)
      }
    })
  }
}

const download = (row) => {
  console.log(row)
  // fullscreenLoading.value = true
  // exportVisitorRegisterReport(row.meetingCode).then((res) => {
  //   fullscreenLoading.value = false
  //   if (res.data.success) {
  //     window.open(res.data.fileUrl)
  //   }
  // })
  row.code = row.meetingCode
  row.relationType = 'HOLD_MEETING'
  exportRegisterRef.value.handleOpen(
    row,
    `报名信息导出`,
    `MEETING_REGISTER_REPORT`
  )
  return false
}

// 跳转统计数据详情
const handleStatisticsDetail = (row) => {
  router.push({
    path: '/exhibitionManage/statisticsDetail',
    query: { meetingCode: row.code },
  })
}

const handlePageConfig = (row) => {
  router.push({
    path: '/exhibitionManage/exHIbitionPageConfig',
    query: { meetingCode: row.code },
  })
}

const handleBrand = (row) => {
  router.push({
    path: '/exhibitionManage/brand',
    query: { meetingCode: row.code },
  })
}
const handleBrandProduct = (row) => {
  router.push({
    path: '/exhibitionManage/productList',
    query: { meetingCode: row.code },
  })
}

/**
 * @description: 添加协同账户
 * @param {*} row
 * @return {*}
 */
const handleSonAccount = (row) => {
  router.push({
    path: '/exhibitionManage/synergy',
    query: { meetingCode: row.code },
  })
}

// 挂载
onActivated(() => {
  getData()
})
</script>

<style lang="scss" scoped>
.exhibitionManageBox {
  background: #f5f5f5;
  height: 80vh;
}
.collapseTitle {
  font-size: 16px;
}
.el-descriptions__label {
  font-size: 14px;
}
.box-card {
  cursor: pointer;
  min-height: 253px;
  background: #fff;
  border-radius: 12px;
  padding: 0 12px 20px 12px;
  margin-bottom: 10px;
  // max-width: 500px;
  .title {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    .editIcon {
      font-size: 20px;
      line-height: 55px;
      color: #409eff;
    }
    .name {
      width: calc(100% - 30px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
  .form {
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
    .form-item {
      display: flex;
      margin-top: 8px;
      .label {
        width: 30%;
        font-size: 12px;
        font-weight: 400;
        color: #909499;
      }
      .item {
        .iconbox {
          font-size: 10px;
          font-weight: 400;
        }
        .yishangjia {
          width: 60px;
          height: 17px;
          line-height: 17px;
          background: #e7faf0;
          border-radius: 2px;
          border: 1px solid #d0f5e0;
          color: #21cf6b;
          position: relative;
          padding-left: 13px;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;
            background: #21cf6b;
            border-radius: 50%;
          }
        }
        .yixiajia {
          width: 60px;
          height: 17px;
          line-height: 17px;
          background: #feeded;
          border-radius: 2px;
          border: 1px solid #ffdbdd;
          color: #ff5153;
          position: relative;
          padding-left: 13px;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;
            background: #ff5153;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .controllBox {
    display: flex;
    justify-content: right;
    margin-top: 10px;
    .btn {
      min-width: 65px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #e9e9e9;
      font-size: 10px;
      font-weight: 400;
      color: #909499;
      text-align: center;
      line-height: 24px;
      margin-left: 10px;
      &:hover {
        background: #409eff;
        color: #fff;
      }
    }
    .dropdown {
      .dropdown-title {
        display: inline-block;
        position: relative;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        color: #fff;
        background-color: #000;
        padding: 0 24px;
        border-radius: 4px;
        cursor: pointer;
        // transition: all 1s ease-in-out;
      }

      .dropdown-content {
        // 定位显示局域
        position: absolute;
        visibility: hidden; // 隐藏
        opacity: 0; // 隐藏
        transition: all 0.6s ease-in-out;
        width: 120px;
        z-index: 99;
        .dropdown-menu {
          margin-top: 4px; // 与title制造距离
          padding: 5px 8px 15px; // 给下面多留一点距离，抵消视觉差
          color: white;
          background-color: rgba($color: #f5f5f5, $alpha: 1);
          border-radius: 4px;
          .menuItem {
            white-space: nowrap;
            font-size: 16px;
            color: white;
            cursor: pointer;
            border-radius: 4px;
            margin-top: 5px;
          }
        }
      }

      &:hover .dropdown-content {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}

.el-dropdown-menu--small .el-dropdown-menu__item {
  margin-top: 5px;
  .btn {
    width: 55px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    font-size: 10px;
    font-weight: 400;
    color: #909499;
    text-align: center;
    line-height: 24px;
  }
}
</style>
