<!--
* @description:
* @fileName typeEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <div>
    <div style="padding: 10px">
      <el-button size="large" type="success" @click="handleSave">保存</el-button>
    </div>
    <el-table ref="multipleTableRef" v-loading="state.listLoading" border :data="state.list" @select="handleSelectionChange" @select-all="handleAllSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column fixed label="排序值" prop="sort" show-overflow-tooltip />
      <el-table-column fixed label="活动标题" prop="title" show-overflow-tooltip />
      <el-table-column fixed label="开始时间" show-overflow-tooltip>
        <template #default="{ row }">
          <span>{{ handleDate(row.startTime) }}</span>
        </template>
      </el-table-column>

      <el-table-column fixed label="结束时间" show-overflow-tooltip>
        <template #default="{ row }">
          <span>{{ handleDate(row.endTime) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="活动地址" prop="address" show-overflow-tooltip />
      <el-table-column label="主办方" prop="organisers" show-overflow-tooltip />
      <el-table-column label="活动人数" prop="peopleNum" show-overflow-tooltip />
      <el-table-column label="活动亮点" prop="sparkle" show-overflow-tooltip />
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="state.queryForm.pageNum" :layout="state.layout" :page-size="state.queryForm.pageSize" :total="state.total" @current-change="handleChangePageNum" @size-change="handleChangePagesize" />
  </div>
</template>
<script>
export default { name: 'Association' }
</script>
<script setup>
import { reactive, ref, onActivated, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { parseTime } from '@/utils/index'
import { activeList } from '@/api/active'
import { getQueryList, saveOrUpdate } from '@/api/exhibitionManage'
const { proxy } = getCurrentInstance()
import { debounce } from '@/utils/debounce'
// 引入路由实例
const route = useRoute()
const multipleTableRef = ref(null)
const state = reactive({
  title: '关联展会',
  listLoading: false,
  queryForm: {
    pageNum: 1,
    pageSize: 10000,
  },
  total: 100,
  layout: 'prev, pager, next',
  list: [],
  selectData: [],
  querySelectData: [],
  meetingCode: '',
  meetingType: '',
})
const fetchData = async () => {
  state.listLoading = true
  const { data } = await activeList(state.queryForm)
  state.list = data.data
  state.total = data.total
  state.listLoading = false
  getQueryData()
}
// 选中item
const handleSelectionChange = (row) => {
  console.log('row==选中item', row)
  state.selectData = row
}
// 选中item
const handleAllSelectionChange = (row) => {
  console.log('row==全选中item', row)
  state.selectData = row
}

// 分页选择
const handleChangePageNum = (val) => {
  state.queryForm.pageNum = val
  fetchData()
  console.log(`output->handleChangePageNum`)
}
const handleChangePagesize = (val) => {
  state.queryForm.pageSize = val
  fetchData()
  console.log(`output->handleChangePagesize`)
}
const handleDate = (val) => {
  return parseTime(val)
}
const getQueryData = async () => {
  try {
    let resp = await getQueryList({ meetingCode: state.meetingCode })
    console.log('resp', resp)
    state.querySelectData = resp.data.activityCodeList
    console.log(' multipleTableRef.value', multipleTableRef.value)
    console.log(' state.list', state.list)
    if (state.querySelectData.length) {
      state.list.forEach((row) => {
        if (state.querySelectData.includes(row.code)) {
          multipleTableRef.value.toggleRowSelection(row, true)
        } else {
          multipleTableRef.value.toggleRowSelection(row, false)
        }
      })
    }
    console.log('tate.querySelectData', state.querySelectData)
  } catch (error) {}
}
const handleSave = debounce(async function () {
  let selectCode = state.selectData.map((item) => item.code)
  console.log('state.selectData', state.selectData)
  if (!selectCode.length)
    return proxy.$baseMessage(`请先选择`, 'error', 'vab-hey-message-error')
  let data = {
    activityCodeList: selectCode,
    meetingCode: state.meetingCode,
    meetingType: state.meetingType,
  }
  try {
    let resp = await saveOrUpdate(data)
    proxy.$baseMessage(`保存成功`, 'success', 'vab-hey-message-success')
  } catch (error) {
    proxy.$baseMessage(`保存失败`, 'error', 'vab-hey-message-error')
  }
})
onActivated(async () => {
  state.meetingCode = route.query.meetingCode
  state.meetingType = route.query.meetingType
  await fetchData()
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}
</style>
